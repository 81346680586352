import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";
import Img from "gatsby-image"
import "./gameItem.sass"
import BetSiteListing from "../../components/BetSiteListing/BetSiteListing"
import Faq from "../../components/Faq/Faq"
import InfoBlocks from "../../components/InfoBlocks/InfoBlocks"
import NewsListing from "../../components/NewsListing/NewsListing"
import GameListing from "../../components/GameListing/GameListing"

export default class GameItem extends React.Component {
  render() {
    let gameItem = this.props.data.prismicGame.data
    let uid = this.props.data.prismicGame.uid
    const sites = this.props.data.allPrismicBetsite.edges
    const relatedNews = this.props.data.allPrismicNewsItem.edges;
    const moreGames = this.props.data.allPrismicGame.edges
    return (
      <Layout>
        <div>
            <Helmet>
                <title>{`${gameItem.metatitle.text}`}</title>
                <meta name="description" content={gameItem.description.text} />
                <meta name="image" content={gameItem.image.url} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script type="application/ld+json">
                  {JSON.stringify(
                    {
                      "@context": "http://schema.org/",
                      "@type": "Review",
                      "itemReviewed": {
                        "@type": "Thing",
                        "name": gameItem.name.text
                      }
                    }
                  )}
                </script>
                <meta property="og:url" content={this.props.location.href} />
                <meta property="og:title" content={gameItem.metatitle.text} />
                <meta property="og:description" content={gameItem.description.text} />
                <meta property="og:image" content={gameItem.image.url} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={gameItem.metatitle.text} />
                <meta name="twitter:description" content={gameItem.description.text} />
                <meta name="twitter:image" content={gameItem.image.url} />
            </Helmet>

          <section className="section">
            <div className="game-section-bg">
              <Img fluid={ gameItem.image.localFile.childImageSharp.fluid }
              style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
              <div className="bg-tint-1"></div>
            </div>
            <div className="container">
              <h1 className="title">
                { gameItem.name.text } betting
              </h1>
              <p dangerouslySetInnerHTML={{ __html: gameItem.content.html }} />
            </div>
          </section>
          <section className="section">
              <div className="container">
                <h2 className="subtitle">Bet on {gameItem.name.text} esports matches</h2>
                <BetSiteListing sites={sites} className="bet-site-listing"/>
              </div>
          </section>
          <section className="section">
              <div className="container">
                <InfoBlocks infoItems={gameItem.info_blocks} />
              </div>
          </section>
          <section className="section">
              <div className="container">
                <h4 className="subtitle">See more esport games</h4>
                  <GameListing games={moreGames} />
              </div>
          </section>
          <section className="section content-section">
              <div className="container">
                <h4 className="subtitle">Related esports news</h4>
                <NewsListing news={relatedNews} />
              </div>
          </section>
          <section className="section">
              <div className="container">
                <Faq faqItems={gameItem.faq} />
              </div>
          </section>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query GameItemBySlug($slug: String!) {
    prismicGame(uid: {eq: $slug}) {
        uid
        data {
          name {
            text
          }
          metatitle {
            text
          }
          description {
            text
          }
          content {
            html
          }
          faq {
            faq_title {
              text
            }
            faq_content {
              text
            }
          }
          info_blocks {
            info_block_title {
              text
            }
            info_block_content {
              html
            }
            info_block_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
    allPrismicBetsite(limit: 4) {
      edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              aff_link {
                url
              }
              terms_and_conditions_link {
                url
              }
              bonus
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicNewsItem (
      limit: 3
      filter: {tags: {eq: $slug}}
      ) {
        edges {
          node {
            uid
            tags
            data {
              title {
                text
              }
              key_tag
              date
              description {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicGame (limit: 3){
        edges {
          node {
            uid
            data {
              name {
                text
              }
              image {
                url
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }

`;

