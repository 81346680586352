import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./NewsListing.sass"

class NewsListing extends Component {
  render() {
    const news = this.props.news
    return (
                <div className="columns is-multiline">
                    {
                    news.map((item, index) => (
                      <div key={index} className="column is-one-third">
                        <Link to={'/news/' + item.node.uid}>
                          <div className="box news-item-box">
                            <Img fluid={ item.node.data.image.localFile.childImageSharp.fluid } alt={ item.node.uid }
                            style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
                            <div className="bg-tint-4"></div>
                            <span className="tag is-dark news-key-tag">{ item.node.data.key_tag } - { item.node.data.date }</span>
                            <h4 className="news-card-title">{ item.node.data.title.text }</h4>
                            {/* <div className="overlay"></div> */}
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
    );
  }
}

export default NewsListing
